import { Link } from 'gatsby';
import * as React from 'react';

const NotFoundPage = () => {
	return (
		<main>
			<title>Nie ma takiej strony</title>
			<div className="flex flex-col justify-center items-center p-8 w-full h-screen md:flex-row">
				<div className="py-8 px-8 text-center border-b md:text-right md:border-b-0 md:border-r border-gray-medium">
					<div className="text-7xl font-bold ">404</div>
					<div>Nie ma takiej strony!</div>
				</div>
				<div className="py-8 px-8">
					<Link
						to={'/'}
						className="inline-block py-4 px-5 text-sm font-bold text-white uppercase transition duration-300 bg-blue hover:bg-blue-shade"
					>
						Strona główna
					</Link>
				</div>
			</div>
		</main>
	);
};

export default NotFoundPage;
